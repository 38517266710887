@import 'src/styles/variables';

.app-header {
  height: 55px;
  display: flex;
  justify-content: space-between;
  cursor: default;

  .menu-item {
    position: relative;
  }

  .active-breadcrumb-item {
    cursor: pointer;
  }

  .disabled-breadcrumb-item {
    cursor: not-allowed;
  }

  .menu-item::after{
    content: "";
    position: absolute;
    z-index: 2;
    top: 6px;
    right: -19px;
    width: 41px;
    height: 41px;
    background: white;
    transform: rotate(-45deg);
    clip-path: polygon(
                    100% 2%,
                    2% 100%,
                    100% 100%
    );
    border-radius: 0 0 10px 0;
  }

  .menu-item:nth-child(4)::after {
    content: none;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100% - 150px)/3);
    color: rgba(28, 24, 45, 1);
    background: linear-gradient(90deg, #E7F0FE -1.16%, #FFFFFF 18.73%, #FFFFFF 96.66%);
  }

  .svg-inline--fa {
    margin-right: 5px;
  }

  .fa-circle-check {
    color: $green;
  }

  .help, .exit {
    color: white;
    background: $dark-blue;
    max-width: 150px;
    min-width: 50px;
    cursor: pointer;
  }

  .active {
    color: white;
    background: $blue;

    &::after{
      background: $blue;
    }
  }
}

@media only screen and (max-width: 700px)  {
  .app-header {
    div {
      font-size: 13px;
    }

    .menu-item::after {
      content: none;
    }

    .svg-inline--fa {
      margin-right: 6px;
    }
  }
}

@media only screen and (max-width: 700px)  {
  .app-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;

    .help, .exit {
      max-width: -webkit-fill-available;
    }

    div {
      padding: 10px;
      width: -webkit-fill-available;
      background: white;
    }
  }
}
