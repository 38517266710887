@import 'src/styles/variables';

.active-preview-page {
  display: flex;
  height: calc(100% - 62px);
  flex-direction: column;
  border-top: 2px solid #D4E4FF;

  .circular-progress-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiCircularProgress-root {
      width: 50px;
      height: 50px;
    }
  }

  nav {
    width: 149px;
    height: 100%;
    border-right: 2px solid #D4E4FF;
  }

  main {
    flex: 1;
    display: flex;
    height: calc(100% - 72px);

    .main-section {
      width: calc(100% - 151px);
      position: relative;

      .btn-wrapper {
        position: absolute;
        bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        left: 0;
        right: 0;
        text-align: center;
      }

      .fa-upload {
        margin-right: 10px;
      }

      .error-message {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $red;
        margin: 15px;
      }
    }
  }

  .page-footer {
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    border-top: 2px solid #D4E4FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;

    .agree-wrapper {
      display: flex;

      .MuiFormControlLabel-root {
        margin: 0 10px;
      }

      .MuiCheckbox-root {
        color: $blue;
      }

      .MuiTypography-root {
        color: $dark-blue;
      }

      a {
        text-decoration: none;
      }
    }

    .btn-wrapper {
      margin: 0 20px;
    }
  }
}

.disabled-preview-page {
  display: none;
}
@media only screen and (max-width: 1050px) {
  .active-preview-page {
    nav {
      display: none;
    }

    main {
      .main-section {
        width: 100%;
        overflow-x: hidden;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .active-preview-page {
    main {
      height: calc(100% - 150px);

      .main-section {
        height: 100%;
      }
    }

    nav {
      display: none;
    }

    .MuiButton-root {
      min-width: max-content;
      font-size: 15px;
    }

    .page-footer {
      padding: 0 10px;
      z-index: 2;

      .agree-wrapper {
        label {
          display: flex;
          align-items: center;
          width: -webkit-fill-available;
        }

        .MuiTypography-root {
          font-size: 13px;
        }
      }

      .MuiFormControlLabel-root {
        margin: 0;
      }

      .btn-wrapper {
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .active-preview-page {
    .page-footer {
      max-height: 150px;
      min-height: 150px;

      .agree-wrapper {
        label {
          width: 150px;
        }
      }
    }
  }
}
