.input-wrapper {
    display: flex;
    flex-direction: column;
    margin: 30px 0 30px 0;
    max-width: 220px;
}

.input-wrapper label {
    margin-bottom: 20px;
}

.input-wrapper input {
    height: 34px;
    width: calc(100% - 18px);
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    font-size: 1em;
    padding: 0 8px;
    color: var(--base-font-color);
}

.input-wrapper .Mui-disabled {
    border: none;
    background-color: #f2f2f2;
    border-radius: 4px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.input-wrapper input::-webkit-inner-spin-button {
    display: none;
}

.disabled-input-wrapper {
    display: none;
}

@media only screen and (max-width: 700px) {
    .input-wrapper {
        max-width: unset;
    }
}