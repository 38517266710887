@import 'src/styles/variables';

.result-page-loaded {
    height: calc(100% - 58px);
}

.select-label {
    margin-bottom: 20px;
    display: block;
}

.user-info {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.load-doc {
    min-width: 200px;
    max-width: 900px;
    margin: auto;
    padding-bottom: 20px;

    .dropzone-container {
        margin-bottom: 40px;
    }

    .load-line {
        width: 100%;
        margin: 20px 0 10px 0;
        border-radius: 20px;

        .MuiLinearProgress-root {
            border-radius: 20px;
        }
    }

    h2 {
        font-size: 45px;
        line-height: 55px;
        font-weight: 600;
        text-align: center;
    }

    h3 {
        font-size: 15px;
        line-height: 25px;
        font-weight: 600;
        text-align: center;
    }

    .types {
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        text-align: center;
    }

    .loading-status {
        border: 2px solid $light-blue;
        border-radius: 10px;
        min-height: 78px;
        padding: 25px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.result-page-unloaded, .disabled-upload-page, .disabled-loading-line {
    display: none;
}

.result-page-loaded, .enabled-upload-page, .active-loading-line {
    display: block;
    border-top: 2px solid $darker-blue;
    border-collapse: collapse;
}

.select-shape .select-shape-of__control, .select-shape .select-shape-of__single-value {
    color: var(--base-font-color);
}

.select-shape .select-shape-of__control--is-focused, .select-shape .select-shape-of__control:hover {
    border: 2px solid #000000;
    box-shadow: none;
}

.upload-status-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.loading-status {
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .task-status {
        color: $blue;
        font-weight: 600;
        margin-bottom: 10px;
        text-transform: capitalize;
        margin-bottom: 10px;
    }

    .task-status-error {
        color: $red;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .uploadStatus {
        display: flex;
        .progress {
            margin-right: 40px;
        }
    }
}

@media only screen and (max-width: 950px) {
    .user-info {
        width: -webkit-fill-available;
        padding: 20px;
    }
    .load-doc {
        width: -webkit-fill-available;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            margin: 20px 0;
        }

        h3 {
            margin: 10px 0;
        }

        .loading-status {
            width: -webkit-fill-available;
        }
    }
}

@media only screen and (max-width: 700px)  {
    .user-info {
        width: -webkit-fill-available;
        flex-direction: column;
        margin-right: 0;
        padding: 20px;

        .select-shape {
            div {
                margin: 0;
            }

            .select-shape-of__control {
                div {
                    width: max-content;
                }
            }
        }

        div {
            width: -webkit-fill-available;
            margin: 10px;

            label {
                margin-bottom: 10px;
            }

            .MuiInput-root {
                margin: 0;
            }
        }
    }
}
