@import 'src/styles/variables';

.active-help-page {
  display: flex;
  height: calc(100% - 55px);
  flex-direction: column;
  border-top: 2px solid #D4E4FF;

  nav {
    width: 418px;
    height: 100%;
    border-right: 2px solid #D4E4FF;
  }

  main {
    flex: 1;
    display: flex;

    section {
      padding: 40px;
    }
  }

  .page-footer {
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    width: 100%;
    border-top: 2px solid #D4E4FF;
  }
}

.disabled-help-page {
  display: none;
}

@media only screen and (max-width: 700px)  {
  .active-help-page {
    nav {
      display: none;
    }

    main {
      section {
        padding: 20px;
      }
    }
  }
}
