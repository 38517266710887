@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat-Regular.ttf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat-SemiBold.ttf) format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat-Light.ttf) format('opentype');
  font-weight: 300;
  font-style: normal;
}