$red: #cf3f4f;
$light-grey: #f2f2f2;
$dark-grey: #9f9f9f;

$white: #ffffff;
$black: #000000;
$dark-blue: #1C182D;
$blue: #327DCC;
$blue-background-selected: #E7F0FE;
$light-blue: #E7F0FE;
$light-blue-selected: #F3F8FF;
$lightest-blue:#FAFCFF;
$darker-blue:#D4E4FF;
$grey: #8d8b96;
$green: #21A112;
$dark-green: #17700d;
$magenta: #CE07B4FF;

$navigation-pane-width: 350px;
$border-radius: 30px;
$blue-background-selected: rgba($dark-blue, 0.1);
$red-background-selected: rgba($red, 0.1);
$box-shadow: 0 0 14px 2px #00000012;

