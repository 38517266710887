@import 'src/styles/variables';

.result-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .result-page-main-part {
        display: flex;
        height: calc(100% - 70px);

        .circular-progress-wrapper {
            height: 100%;
            width: -webkit-fill-available;
            display: flex;
            align-items: center;
            justify-content: center;

            .MuiCircularProgress-root {
                width: 50px;
                height: 50px;
            }
        }
    }

    .canvas {
        width: calc(100% - 360px);
        position: relative;
    }

    .canvas-settings {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        padding: 5px 10px;
        gap: 0 15px;
        align-items: flex-start;
        width: -webkit-fill-available;
        pointer-events: none;

        .fit-to-screen-menu {
            .slider-container {
                display: flex;
                align-items: center;
                height: 35px;
                pointer-events: all;

                .MuiSlider-root {
                    margin-right: 15px;
                }

                .MuiSlider-thumb {
                    width: 12px;
                    height: 12px;
                    color: $blue;
                }

                .MuiSlider-rail {
                    width: 150px;
                    color: #C7D9F1;
                }

                .MuiSlider-track {
                    color: $blue;
                }

                .MuiSlider-valueLabelOpen {
                    display: none;
                }

                .percent {
                    width: 40px;
                }
            }

            .fit-to-screen {
                font-weight: 600;
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 5px;
                cursor: pointer;
                pointer-events: all;

                &:hover {
                    color: $blue;
                }

                &.disabled {
                    pointer-events: none;
                    color: $dark-grey;
                }
            }
        }

        .lock-aspect-ratio-wrapper {
            display: flex;
            align-items: flex-start;
            position: absolute;
            left: calc(50% - 120px);

            label {
                padding-top: 5px;
                margin-right: 25px;
                pointer-events: all;
            }

            .MuiCheckbox-root {
                padding: 0 5px 0 0;
                pointer-events: all;
            }

            .reset-scaling-btn {
                font-weight: 600;
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 5px;
                cursor: pointer;
                padding-top: 5px;
                pointer-events: all;

                &:hover {
                    color: $blue;
                }
            }
        }
    }

    .menu {
        background-color: $white;
        color: $blue;
        max-width: 110px;
        min-width: 110px;
        line-height: 25px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-right: 2px solid #D4E4FF;

        .action-group {
            padding: 15px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-bottom: 2px solid #D4E4FF;

            &:hover {
                background-color: $light-blue;
                color: $dark-blue;
            }

            .svg-inline--fa {
              margin-bottom: 5px;
            }
        }

        .scale-group {
            display: flex;
            align-items: center;

            div {
                margin-right: 5px;
            }
        }

        .undo-redo-group {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            color: $dark-blue;
            padding: 10px 5px;

            .undo {
                border-right: 2px solid $darker-blue;
                padding-right: 5px;
            }

            .undo, .redo {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;

                &:hover {
                    color: $blue;
                }
            }
            & .disabled {
                color: $darker-blue;
                cursor: not-allowed;

                &:hover {
                    color: $darker-blue;
                }
            }

            .svg-inline--fa {
                width: 17px;
                height: 25px;
            }
        }
    }

    .details-menu {
        width: 250px;
        padding: 20px;
        border-right: 2px solid #D4E4FF;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        h3 {
            font-size: 25px;
            line-height: 27px;
            margin-top: 0;
            margin-bottom: 20px;
        }

        h4 {
            line-height: 25px;
            margin-top: 0;
            margin-bottom: 10px;
            font-weight: 600;
        }

        .description {
            line-height: 20px;
            margin-bottom: 20px;
        }

        .scale {
            .btn-group {
                margin-bottom: 0;
            }

            .lock-aspect-ratio-wrapper {
                margin-bottom: 10px;

                .MuiCheckbox-root {
                    color: $blue;
                }

                .MuiTypography-root {
                    color: $dark-blue;
                }

                .MuiFormControlLabel-root {
                    height: 35px;
                }
            }
        }

        .move {
            .btn-group {
                justify-content: center;
            }
        }

        .btn-group {
            display: flex;
            margin-bottom: 20px;
        }

        .top-btn {
            display: flex;
            justify-content: center;
        }

        .btn-wrapper {
            width: 64px;
            margin-right: 10px;
        }

        .btn {
            background-color: $white;
            color: $blue;
            border-radius: 10px;
            width: 55px;
            height: 55px;
            border: 2px solid $darker-blue;
            font-size: 22px;
            line-height: 25px;
            padding: 0;
            margin-bottom: 10px;

            &:hover {
                background-color: $light-blue;
                color: $dark-blue;
            }
        }

        .fit-to {
            font-weight: 600;
            font-size: 12px;
            width: fit-content;
            height: 30px;
            padding: 0 15px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .safety-line-dots {
                width: 20px;
                height: 1px;
                border-top: 2px dashed $green;
                margin-right: 10px;
            }

            .bleed-line-dots {
                width: 20px;
                height: 1px;
                border-top: 2px dashed #00A3FF;
                margin-right: 10px;
            }

            .cut-line-dots {
                width: 20px;
                height: 1px;
                border-top: 2px solid magenta;
                margin-right: 10px;
            }

            .line-text {
                line-height: 1;
            }
        }

        .scale-by-percentage {
            font-weight: 600;
            font-size: 12px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .MuiInputBase-root {
                background-color: $white;
                color: $blue;
                border-radius: 30px;
                border: 2px solid $darker-blue;
                height: 30px;
                margin-right: 10px;
                width: 80%;

                .MuiInputBase-input {
                    color: $blue;
                }

                .MuiInputAdornment-positionEnd {
                    .MuiTypography-root {
                        color: $blue;
                    }
                }
                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .MuiButton-root {
                font-weight: 600;
                font-size: 12px;
                height: 30px;
                padding: 0 15px;
                border-radius: 30px;
                margin-bottom: 0;
                margin-right: 0;
                width: 100%;
            }
        }

        .reset {
            font-weight: 600;
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: 5px;
            cursor: pointer;

            &:hover {
                color: $blue;
            }
        }
    }

    .page-footer {
        height: 65px;
        min-height: 65px;
        max-height: 65px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        border-top: 2px solid #D4E4FF;

        .caption {
            line-height: 20px;
            display: flex;

            div {
                margin-right: 10px;
            }

            .safety-line, .bleed-line, .cut-line {
                display: flex;
                align-items: center;
            }

            .safety-line {
                color: $green;
                .safety-line-dots {
                    width: 60px;
                    height: 1px;
                    border-top: 2px dashed $green;
                }
            }

            .bleed-line {
                color: #00A3FF;
                .bleed-line-dots {
                    width: 60px;
                    height: 1px;
                    border-top: 2px dashed #00A3FF;
                }
            }

            .cut-line {
                color: $magenta;
                .cut-line-dots {
                    width: 60px;
                    height: 1px;
                    border-top: 2px solid $magenta;
                }
            }
        }
    }
}

.result-page main {
    flex-direction: column;
}

h3 {
    font-size: 1.3em;
}

.result-page h3 .line {
    display: block;
    margin: 20px 0;
}

@media only screen and (max-width: 1070px) {
    .result-page {
        .canvas-settings {
            .lock-aspect-ratio-wrapper {
                left: calc(50% - 80px);
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .result-page {
        .canvas-settings {
            .lock-aspect-ratio-wrapper {
                left: calc(50% - 40px);
            }
        }
    }
}

@media only screen and (max-width: 920px) {
    .result-page {
        .canvas-settings {
            .lock-aspect-ratio-wrapper {
                left: calc(50% - 20px);
            }
        }
    }
}

@media only screen and (max-width: 850px) {
    .result-page {
        height: fit-content;

        .canvas {
            width: -webkit-fill-available;
            height: 850px;
            min-height: 850px;
            overflow-y: hidden;
            overflow-x: hidden;
        }

        .canvas-settings {
            top: 10px;
            left: 15px;
            padding: 15px;

            .slider-container {
                .MuiSlider-root {
                    padding: 10px 0;
                }
            }
        }

        .result-page-main-part {
            flex-direction: column;
        }

        .details-menu {
            border-right: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc(100% - 40px);

            h3 {
                margin-bottom: 10px;
            }

            .description, .btn-group {
                margin-bottom: 5px;
            }

            .move, .scale {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        .menu {
            flex-direction: row;
            max-width:  -webkit-fill-available;
            border-bottom: 2px solid #D4E4FF;
            border-right: none;
            justify-content: space-between;
            height: 70px;

            .action-group {
                border: none;
                border-right: 2px solid #D4E4FF;
                padding: 10px;
            }

            div {
                display: flex;
                flex-direction: row;
                text-align: center;
            }

            .undo-redo-group {
                flex-direction: row;
                padding: 0;

                .undo, .redo {
                    padding: 10px;
                    height: -webkit-fill-available;
                    border-left: 2px solid #D4E4FF;
                }
                .undo {
                    border-right: 0;
                }
            }
        }
        .page-footer {
            .caption {
                flex-direction: column;
                margin: 0 5px;

                div {
                    margin-right:0;
                }

                .safety-line-dots, .bleed-line-dots, .cut-line-dots {
                    margin-right: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .result-page {
        height: fit-content;

        .canvas {
            height: 500px;
            min-height: 500px;
            overflow-x: hidden;
        }

        .details-menu {
            h3, .description {
                display: none;
            }
        }
    }
}
