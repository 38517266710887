@import 'src/styles/variables';
.app {
    .btn-wrapper {
        .btn-label {
            text-align: center;
        }
        .MuiButton-root{
            height: 50px;
            font-size: 20px;
            line-height: 27px;
            font-weight: 600;
            border-radius: 5px;
            transition: all .7s ease-in-out;;
            cursor: pointer;
            background-color: $light-grey;
            color: $dark-grey;
            text-transform: none;
            min-width: 50px;
        }

        .MuiButton-root:hover {
            background-color: $grey;
            color: $black;
        }

        .Mui-disabled {
            &:hover{
                background-color: white;
                cursor: not-allowed;
            }
        }

        .active-btn {
            display: block;
        }

        .hidden-btn {
            display:none;
        }

        .Mui-disabled {
            opacity: 0.5;
            cursor: no-drop;
        }

        .finish-btn {
            padding: 6px 20px;
            background-color: $green;
            color: white;
            border-radius: 30px;
            height: 40px;

            &:hover {
                background-color: $dark-green;
                color: white;
            }
        }

        .back-btn {
            padding: 6px 20px;
            height: 40px;
            border: 2px solid $dark-blue;
            background-color: white;
            color: $dark-blue;
            border-radius: 30px;

            &:hover {
                background-color: rgba(28, 24, 45, 0.25);
                color: $dark-blue;
            }
        }
    }
}

@media only screen and (max-width: 859px) {
    .app {
        .MuiButton-root{
            font-size: 16px;
            padding: 5px 10px;
        }

        .action-btns .MuiButton-root{
            margin-right: 10px;
        }
        .active-preview-page {
            .page-footer {
                .btn-wrapper {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
