@import 'src/styles/variables';
@import 'src/styles/fonts';

html,
body {
  min-height: 100%;
  margin: 0;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  color: $dark-blue;
}