@import 'src/styles/variables';

.dropzone-container {
    align-items: center;
    border: 2px dashed $blue;
    border-radius: 10px;
    color: $dark-blue;
    cursor: pointer;
    height: 225px;
    justify-content: center;
    margin: 50px 0;
    outline: none;
    padding: 20px;
    transition: all .2s linear;

    .blue-text {
        color: $blue;
        font-weight: 600;
    }

    .dropzone-img {
        background-color: $blue;
        width: 70px;
        height: 70px;
        color: white;
        font-size: 27px;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        background-color: $light-blue;
        opacity: 0.4;
    }

    &.disabled-dropzone {
        border: 2px dashed $dark-grey;
        background-color: $light-grey;
        opacity: 0.4;
    }

    &.disabled-dropzone:hover {
        cursor: no-drop;
    }

    .dropzone-error-message {
        .too-many-files, .file-invalid-type {
            display: none;
        }

        .too-many-files:first-child, .file-invalid-type:first-child {
            display: block;
        }
    }
}

.smooth {
    opacity: 0.4;
}

.dropzone-container section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.dropzone-container section div {
    margin: 5px;
}

.enabled-dropzone {
    display: block;
}

.dropzone-error-message {
    color: $red;
}

.hidden-dropzone {
    display: none;
}

.active-dropzone {
    display: block;
}

@media only screen and (max-width: 950px) {
    .dropzone-container {
        width: -webkit-fill-available;

    }
}

@media only screen and (max-width: 700px) {
    .dropzone-container {
        width: calc(100% - 40px);
        margin: 20px;
        height: 160px;
    }

    .dropzone-container section div {
        margin: 5px 0;
    }
}
